import { Canvas } from '@/types';
import { plateSets } from '@/types/plate-sets';

export class CanvasService {
    public createNew(): Canvas {
        return {
            name: 'New bead canvas',
            stage: 'document',
            size: {
                width: 29,
                height: 29
            },
            plateSize: {
                width: 29,
                height: 29
            },
            beadSize: plateSets[1].beadSize,
            colors: [],
            data: [],
            dataMap: new Map(),
            backgroundImage: {
                scaleType: 'pixel-perfect',
                scale: 1,
                offset: {
                    x: 0,
                    y: 0
                }
            }
        };
    }
}

export default new CanvasService();

import { Bead, Canvas, CanvasBackgroundImage, CanvasStage } from '@/types'
import { computed, ComputedRef } from 'vue'
import { StoreBase, StoreType } from './store-base'

export interface AppStore extends Object {
    canvases: Canvas[]
    currentCanvasIndex?: number
    currentStage?: CanvasStage
}

class Store extends StoreBase<AppStore> {
    constructor() {
        super(StoreType.localStorage, 'app')
    }

    protected defaultData(): AppStore {
        return {
            canvases: []
        }
    }

    public get currentCanvas(): ComputedRef<Canvas | undefined> {
        return computed(() => this.state.canvases[this.state.currentCanvasIndex ?? 0])
    }

    public setCurrentCanvasIndex(index?: number) {
        this.reactiveState.currentCanvasIndex = index
    }

    public setAllCanvases(allCanvases: Canvas[]) {
        this.reactiveState.canvases = allCanvases
    }

    public addCanvas(newCanvas: Canvas) {
        this.reactiveState.canvases.push(newCanvas)
    }

    public replaceCanvas(newCanvase: Canvas, index: number) {
        this.reactiveState.canvases.splice(index, 1, newCanvase)
        this.reactiveState.canvases = [...this.reactiveState.canvases]
    }

    public removeCanvas(index: number) {
        this.reactiveState.canvases.splice(index, 1)
    }

    public setCurrentStage(value: CanvasStage) {
        this.reactiveState.currentStage = value
    }

    public setCurrentCanvasProperty<K extends keyof Canvas>(property: K, value: Canvas[K]) {
        const currentCanvas = this.reactiveState.canvases[this.state.currentCanvasIndex ?? 0]

        if (currentCanvas != null) {
            const newCanvas = Object.assign({}, { ...currentCanvas })
            newCanvas[property] = value

            this.replaceCanvas(newCanvas, this.state.currentCanvasIndex ?? 0)
        }
    }

    public setCurrentCanvasBackgroundImageProperty<K extends keyof CanvasBackgroundImage>(property: K, value: CanvasBackgroundImage[K]) {
        const currentCanvas = this.reactiveState.canvases[this.state.currentCanvasIndex ?? 0]

        if (currentCanvas != null) {
            const newBackgroundImage = Object.assign({}, { ...currentCanvas.backgroundImage })
            newBackgroundImage[property] = value
            
            this.setCurrentCanvasProperty('backgroundImage', newBackgroundImage)
        }
    }

    public setCurrentCanvasBead(x: number, y: number, bead: Bead | undefined) {
        const currentCanvas = this.reactiveState.canvases[this.state.currentCanvasIndex ?? 0]

        if (currentCanvas != null) {
            // Clone the data map
            const newMap = new Map(currentCanvas.dataMap)
            
            if (bead == null) {
                // Set the bead to nothing
                newMap.set(`${x}:${y}`, undefined)
            } else {
                // Cloe the bead so it cannot be modified from outside
                const newBead = Object.assign({}, bead)
                
                // Set bead properties
                newBead.color = bead.color

                // Update the bead data
                newMap.set(`${x}:${y}`, newBead)
            }
            this.setCurrentCanvasProperty('dataMap', newMap)
        }
    }

    public getCurrentCanvasBead(x: number, y: number): Bead | undefined {
        const currentCanvas = this.currentCanvas.value

        if (currentCanvas != null) {
            return currentCanvas.dataMap.get(`${x}:${y}`)
        }

        return undefined;
    }
}

const appStore = new Store()
export default appStore

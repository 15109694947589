import { Size } from '.';

export interface Plate {
    group: plateSetGroups,
    name: string,
    size: Size,
    beadSize:number,
}

// The plate set groups
export type plateSetGroups = 'hama' | 'photo-pearls'

// Hama mini plate
export const hamaMiniPlate: Plate = {
    group: 'hama',
    name: 'Hama mini',
    size: {
        width: 57,
        height: 57,
    },
    beadSize: 2.6,
};

// Hama midi plate
export const hamaMidiPlate: Plate = {
    group: 'hama',
    name: 'Hama midi',
    size: {
        width: 29,
        height: 29,
    },
    beadSize: 5.17
};

// Hama maxi plate
export const hamaMaxiPlate: Plate = {
    group: 'hama',
    name: 'Hama maxi',
    size: {
        width: 16,
        height: 16,
    },
    beadSize: 10
};

// Photo pearls plate
export const photoPearls: Plate = {
    group: 'photo-pearls',
    name: 'PhotoPearls',
    size: {
        width: 16,
        height: 16,
    },
    beadSize: 5.17
};

// Class containing all default color sets available
export const plateSets: Plate[] = [
    hamaMiniPlate,
    hamaMidiPlate,
    hamaMaxiPlate,
    photoPearls
];
import { reactive, readonly, UnwrapNestedRefs, watchEffect } from 'vue'

export enum StoreType {
    memory,
    localStorage,
    sessionStorage
}

export abstract class StoreBase<T extends Record<string, any>> {
    protected reactiveState: UnwrapNestedRefs<T>;

    constructor(type: StoreType, storageName: string) {
        let data;

        if (type === StoreType.memory) {
            // Memory store, just creates a new state object from data()
            data = this.defaultData();

        } else if (type === StoreType.localStorage) {
            const jsonStore = localStorage.getItem(storageName);
            data = jsonStore == null ? this.defaultData() : JSON.parse(jsonStore, StoreBase.reviver);

        } else if (type === StoreType.sessionStorage) {
            const jsonStore = sessionStorage.getItem(storageName);
            data = jsonStore == null ? this.defaultData() : JSON.parse(jsonStore, StoreBase.reviver);

        } else {
            throw new Error('Invalid store type');
        }

        this.setup(data);
        this.reactiveState = reactive(data);

        // Automatically save changes to storage (if not memory-only store)
        watchEffect(() => {
            if (type === StoreType.localStorage) {
                localStorage.setItem(storageName, JSON.stringify(this.reactiveState, StoreBase.replacer));

            } else if (type === StoreType.sessionStorage) {
                sessionStorage.setItem(storageName, JSON.stringify(this.reactiveState, StoreBase.replacer));
            }
        });
    }

    /**
     * Can be used when converting storage to JSON, so maps are converted to custom object with arrays.
     */
    public static replacer(key: string, value: any) {
        if (value instanceof Map) {
            return {
                dataType: 'Map',
                value: Array.from(value.entries()).filter(x => x[1] != null)
            }
        } else {
            return value
        }
    }

    /**
     * Can be used when converting storage from JSON, so maps are converted from custom object with arrays.
     */
    public static reviver(key: string, value: any) {
        if (typeof value === 'object' && value != null) {
            if (value.dataType === 'Map') {
                return new Map(value.value)
            }
        }
        return value
    }

    /**
     * Override to provide the initial state of the store.
     */
    protected abstract defaultData(): T;

    /**
     * Override to setup any initial state after it has been loaded.
     */
    protected setup(data: T): void { }

    /**
     * Gets the state as read only, cannot be mutated.
     */
    public get state() {
        return readonly(this.reactiveState) as T;
    }
}

const defaults = {
    tileSize: 60,
    refreshInterval: 16,
    saveInterval: 10000,
    standardPegboardSizes: {
        maxi: 16,
        midi: 29,
        mini: 57
    },
    standardBeadSizes: {
        maxi: 10,
        midi: 5.17,
        mini: 2.63
    }
}

/**
 * The default properties etc.
 */
export default defaults
import appStore from "@/store/app-store"
import settingsStore from "@/store/settings-store"

/**
* Returns the size of the number of selected beads.
*/
export const getTileSize = (width: number = 1): number => {
   if (appStore.currentCanvas.value == null) { return 0 }
   
   let beadSize = (Math.log(appStore.currentCanvas.value.beadSize) * 30 * settingsStore.state.zoom)
   let value = width * beadSize
   return value
}

/** Returns the size of the current canvas in pixels */
export const getCanvasPixelSize = (): { width: number, height: number } => {
    const value = {
        width: getTileSize(appStore.currentCanvas.value?.size.width ?? 0) + 2,
        height: getTileSize(appStore.currentCanvas.value?.size.height ?? 0) + 2,
    }

    return value;
}

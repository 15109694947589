import { Tools, ToolSizes } from '@/types';
import { StoreBase, StoreType } from './store-base';

export interface SettingsStore extends Object { 
    zoom: number
    nightMode: boolean
    currentTool: Tools
    displayBackgroundImage: boolean
    toolSize: ToolSizes
}

class Store extends StoreBase<SettingsStore> {
    constructor() {
        super(StoreType.localStorage, 'settings')
    }

    protected defaultData(): SettingsStore {
        return {
            zoom: 1,
            nightMode: false,
            currentTool: 'hand',
            displayBackgroundImage: true,
            toolSize: 'small'
        }
    }

    public setZoomLevel(value: number) {
        this.reactiveState.zoom = value
    }

    public resetZoomLevel() {
        this.setZoomLevel(1)
    }

    public setNightMode(value: boolean) {
        this.reactiveState.nightMode = value
    }

    public setCurrentTool(value: Tools) {
        this.reactiveState.currentTool = value
    }

    public setToolSize(toolSize: ToolSizes) {
        this.reactiveState.toolSize = toolSize
    }

    public setDisplayBackgroundImage(value: boolean) {
        this.reactiveState.displayBackgroundImage = value
    }

    public toggleDisplayBackgroundImage() {
        this.setDisplayBackgroundImage(!this.reactiveState.displayBackgroundImage)
    }
}

const appStore = new Store()
export default appStore

import { createApp } from 'vue'
import App from './app.vue'
import './registerServiceWorker'
import router from './router'
import FloatingVue from 'floating-vue'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"

library.add(fas)

createApp(App)
    .component('fa', FontAwesomeIcon)
    .use(FloatingVue, {
        themes: {
            'info-tooltip': {
                $extend: 'info-tooltip'
            }
        }
    })
    .use(router)
    .mount('#app')

<template>
<div class="context-menu-item">
    <slot></slot>
</div>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>
.context-menu-item {
    padding: $margin-7 $margin-15;
    background-color: $color-bw-90;
    font-family: $font-family;

    &:hover {
        background-color: $color-primary;
        color: $color-white;
    }
}
</style>

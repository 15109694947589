<template>
<router-view />
</template>

<script setup lang="ts">

</script>

<style lang="scss">
#app {
    font-family: $font-family;
    // font: caption;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    overflow: hidden;

    display: flex;
    flex-direction: column;
}
</style>

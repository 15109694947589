<template>
<div class="panel">
    <h1>How do you want to create your bead plate?</h1>
    <p>You may either start of from an empty canvas where you set its properties, or import an image that you want to use as a template.</p>
</div>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>
.panel {

}
</style>

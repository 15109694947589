<template>
<div class="context-menu-split"></div>
</template>

<script setup lang="ts">

</script>

<style lang="scss" scoped>
.context-menu-split {
    background-color: $color-bw-70;
    outline: $color-bw-90 solid $margin-3;
    height: 1px;
    min-width: 30px;
}
</style>

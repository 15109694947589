import { Color } from '.'

export interface ColorSet {
    group: string,
    name: string,
    colors: Color[]
}

// The color set groups
export const colorSetGroups = {
    hama: 'Hama',
    photoPearls: 'PhotoPearls',
}

// Hama mini color set
export const hamaMiniColors: ColorSet = {
    group: "Hama",
    name: 'Hama mini colors',
    colors: [
        { name: 'Hama mini 01 - White', value: '#ffffff', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 02 - Cream', value: '#eee0bb', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 03 - Yellow', value: '#f3b806', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 04 - Orange', value: '#ff4f00', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 05 - Red', value: '#d0110c', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 06 - Pink', value: '#d18294', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 07 - Purple', value: '#513c80', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 08 - Navy', value: '#245090', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 09 - Blue', value: '#1a63ac', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 10 - Green', value: '#005e36', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 11 - Mint', value: '#6ca779', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 12 - Cocoa', value: '#351a09', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 13 - Red', value: '#d0110c', translucent: true, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 14 - Yellow', value: '#f3b806', translucent: true, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 15 - Blue', value: '#1a63ac', translucent: true, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 16 - Green', value: '#005e36', translucent: true, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 17 - Grey', value: '#6d6f73', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 18 - Black', value: '#000000', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 19 - Clear', value: 'rgba(255, 255, 255, 0)', translucent: true, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 20 - Chestnut', value: '#713422', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 21 - Caramel', value: '#af6629', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 22 - Ruby', value: '#8a2229', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 24 - Purple', value: '#323164', translucent: true, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 26 - Blush', value: '#e48565', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 27 - Beige', value: '#dcba86', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 28 - Khaki', value: '#333a2c', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 29 - Claret', value: '#852030', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 30 - Burgundy', value: '#4a2e32', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 31 - Teal', value: '#7fadc4', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 32 - Neon Pink', value: '#b42a5d', translucent: false, glowInTheDark: false, shiny: true },
        { name: 'Hama mini 33 - Cerise', value: '#bc2333', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 34 - Neon Yellow', value: '#e4da55', translucent: false, glowInTheDark: false, shiny: true },
        { name: 'Hama mini 35 - Neon Red', value: '#ad2f27', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 36 - Neon Blue', value: '#24599a', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 37 - Neon Green', value: '#42803f', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 38 - Neon Orange', value: '#c44424', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 42 - Flourescent Green', value: '#4a8437', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 43 - Lemon', value: '#f8e447', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 44 - Coral', value: '#b93539', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 45 - Lilac', value: '#4d3d5f', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 46 - Aqua', value: '#5aa7dc', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 47 - Lime', value: '#629648', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 48 - Rose Pink', value: '#cf80a7', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 49 - Turquoise', value: '#61a9b7', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 55 - Green', value: '#e5e8d9', translucent: false, glowInTheDark: true, shiny: false },
        { name: 'Hama mini 56 - Red', value: '#e4beb0', translucent: false, glowInTheDark: true, shiny: false },
        { name: 'Hama mini 57 - Blue', value: '#a5b5bf', translucent: false, glowInTheDark: true, shiny: false },
        { name: 'Hama mini 60 - Mustard', value: '#dc9737', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 61 - Gold', value: '#895f3c', translucent: false, glowInTheDark: false, shiny: true },
        { name: 'Hama mini 62 - Silver', value: '#3a3836', translucent: false, glowInTheDark: false, shiny: true },
        { name: 'Hama mini 63 - Bronze', value: '#655941', translucent: false, glowInTheDark: false, shiny: true },
        { name: 'Hama mini 64 - Pearl', value: '#a78c6b', translucent: false, glowInTheDark: false, shiny: true },
        { name: 'Hama mini 70 - Light Grey', value: '#959fa9', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 71 - Dark Grey', value: '#333333', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 72 - Pink', value: '#bc577c', translucent: true, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 73 - Aqua', value: '#337ba9', translucent: true, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 74 - Lilac', value: '#5d4a89', translucent: true, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 75 - Tan', value: '#ae7947', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'Hama mini 76 - Brown', value: '#885929', translucent: false, glowInTheDark: false, shiny: false },
    ]
}

// Hama midi color set
export const hamaMidiColors: ColorSet = {
    group: "Hama",
    name: 'Hama midi colors',
    colors: [
        { name: 'black', value: '#000000', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'white', value: '#ffffff', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'midi', value: '#ff5612', translucent: false, glowInTheDark: false, shiny: false },
    ]
}

// Hama maxi color set
export const hamaMaxiColors: ColorSet = {
    group: "Hama",
    name: 'Hama maxi colors',
    colors: [
        { name: 'black', value: '#000000', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'white', value: '#ffffff', translucent: false, glowInTheDark: false, shiny: false },
        { name: 'maxi', value: '#12ff98', translucent: false, glowInTheDark: false, shiny: false },
    ]
}

// Photo pearls color set
export const photoPearlsColors: ColorSet = {
    group: "PhotoPearls",
    name: 'PhotoPearls colors',
    colors: [
        { name: 'PhotoPearls 01 - Black', value: '#000000', translucent: false, glowInTheDark: false, shiny: false },
    ]
}

// Class containing all default color sets available
export const colorSets: ColorSet[] = [
    hamaMiniColors,
    hamaMidiColors,
    hamaMaxiColors,
    photoPearlsColors
]

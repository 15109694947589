import appStore from "@/store/app-store";
import settingsStore from "@/store/settings-store";
import { Bead, Canvas, Color } from "@/types";
import { Ref } from "vue";
import { getTileSize, getCanvasPixelSize } from "./common";

export class CanvasPainter {
    private backgroundImageCanvas: Ref<HTMLCanvasElement | undefined>
    private backgroundCanvas: Ref<HTMLCanvasElement | undefined>
    private beadsCanvas: Ref<HTMLCanvasElement | undefined>
    private foregroundCanvas: Ref<HTMLCanvasElement | undefined>

    private mapBuffer: Map<string, Bead | undefined>

    constructor(backgroundImageCanvas: Ref<HTMLCanvasElement | undefined>,
        backgroundCanvas: Ref<HTMLCanvasElement | undefined>,
        beadsCanvas: Ref<HTMLCanvasElement | undefined>,
        foregroundCanvas: Ref<HTMLCanvasElement | undefined>,
        mapBuffer: Map<string, Bead | undefined>) {
        this.backgroundImageCanvas = backgroundImageCanvas
        this.backgroundCanvas = backgroundCanvas
        this.beadsCanvas = beadsCanvas
        this.foregroundCanvas = foregroundCanvas
        this.mapBuffer = mapBuffer
    }

    public paintEverything(canvas: HTMLCanvasElement, needsPaintLocations: { x: number, y: number}[]) {
        if (appStore.currentCanvas.value == null) { return; }

        // Instantiate the context to draw all layers on
        const context: CanvasRenderingContext2D = canvas.getContext('2d')!;

        // Properties
        const nightMode = settingsStore.state.nightMode;
        const tileSize = getTileSize();

        // Determine if all tiles (beads) needs to be drawn
        const drawBackground = this.backgroundCanvas.value == null;
        const drawAllBeads = this.beadsCanvas.value == null || needsPaintLocations.length === 0
        const drawForeground = this.foregroundCanvas.value == null;

        if (drawBackground || drawAllBeads || drawForeground) {
            // Everything needs to be drawn

            // Set properties on the canvas element
            // (this will also have the side effect of clearing the entire canvas)
            const canvasPixelSize = getCanvasPixelSize();
            canvas.width = canvasPixelSize.width;
            canvas.height = canvasPixelSize.height;
            canvas.style.width = (canvasPixelSize.width / 2) + "px";
            canvas.style.height = (canvasPixelSize.height / 2) + "px";

            // Instantiate all the canvases (buffers)
            this.backgroundCanvas.value = this.createCanvasFrom(canvas);
            this.beadsCanvas.value = this.createCanvasFrom(canvas);
            this.foregroundCanvas.value = this.createCanvasFrom(canvas);

            // Create buffers
            const backgroundBuffer = this.backgroundCanvas.value.getContext('2d')!;
            const beadsBuffer = this.beadsCanvas.value.getContext('2d')!;
            const foregroundBuffer = this.foregroundCanvas.value.getContext('2d')!;

            // Make background dark on night mode
            if (nightMode) {
                if (this.backgroundImageCanvas.value == null) {
                    // No background image exist, use black
                    backgroundBuffer.fillStyle = 'rgb(0, 0, 0)';
                } else {
                    // Background image exists, use a little bit transparency
                    backgroundBuffer.fillStyle = 'rgba(0, 0, 0, 0.9)';
                }
                backgroundBuffer.fillRect(0, 0, canvasPixelSize.width, canvasPixelSize.height);
            }

            // Iterate through all beads
            for (let y = 0; y < appStore.currentCanvas.value.size.height; y++) {
                for (let x = 0; x < appStore.currentCanvas.value.size.width; x++) {
                    this.drawSingleBead(x, y, appStore.currentCanvas.value, beadsBuffer, backgroundBuffer);
                }
            }

            // Draw grid
            // Horizontal lines
            const gridLinesColor = nightMode ? 'rgb(100, 100, 100)' : 'rgb(200, 200, 200)';
            for (let y = 0; y < appStore.currentCanvas.value.size.height + 1; y++) {
                foregroundBuffer!.beginPath();
                foregroundBuffer!.moveTo(0, getTileSize(y));
                foregroundBuffer!.lineTo(getTileSize(appStore.currentCanvas.value.size.width), getTileSize(y));
                foregroundBuffer!.lineWidth = 1;
                foregroundBuffer!.strokeStyle = gridLinesColor;
                foregroundBuffer!.stroke();  
            }

            // Vertical lines
            for (let x = 0; x < appStore.currentCanvas.value.size.width + 1; x++) {
                foregroundBuffer!.beginPath();
                foregroundBuffer!.moveTo(getTileSize(x), 0);
                foregroundBuffer!.lineTo(getTileSize(x), getTileSize(appStore.currentCanvas.value.size.height));
                foregroundBuffer!.lineWidth = 1;
                foregroundBuffer!.strokeStyle = gridLinesColor;
                foregroundBuffer!.stroke();
            }

            // Draw horizontal plate borders
            const verticalPlatesCount = Math.ceil(appStore.currentCanvas.value.size.height / appStore.currentCanvas.value.plateSize.height);
            for (let y = 0; y < verticalPlatesCount + 1; y++) {
                const yPosition = y * appStore.currentCanvas.value.plateSize.height * tileSize;
                foregroundBuffer!.beginPath();
                foregroundBuffer!.moveTo(0, yPosition);
                foregroundBuffer!.lineTo(appStore.currentCanvas.value.size.width * tileSize, yPosition);
                foregroundBuffer!.lineWidth = 5;
                foregroundBuffer!.strokeStyle = gridLinesColor;
                foregroundBuffer!.stroke();
            }

            // Draw vertical plate borders
            const horizontalPlatesCount = Math.ceil(appStore.currentCanvas.value.size.width / appStore.currentCanvas.value.plateSize.width);
            for (let x = 0; x < verticalPlatesCount + 1; x++) {
                const xPosition = x * appStore.currentCanvas.value.plateSize.width * tileSize;
                foregroundBuffer!.beginPath();
                foregroundBuffer!.moveTo(xPosition, 0);
                foregroundBuffer!.lineTo(xPosition, appStore.currentCanvas.value.size.height * tileSize);
                foregroundBuffer!.lineWidth = 5;
                foregroundBuffer!.strokeStyle = gridLinesColor;
                foregroundBuffer!.stroke();
            }

            // Render the buffers on the real canvas
            if (this.backgroundImageCanvas.value != null && settingsStore.state.displayBackgroundImage === true) {
                context.drawImage(this.backgroundImageCanvas.value, 0, 0);
            }
            context.drawImage(this.backgroundCanvas.value, 0, 0);
            context.drawImage(this.beadsCanvas.value, 0, 0);
            context.drawImage(this.foregroundCanvas.value, 0, 0);

        } else {
            // Draw only the beads that has been changed

            // Get the buffer from the existing canvas
            const backgroundBuffer = this.backgroundCanvas.value!.getContext('2d')!;
            const beadsBuffer = this.beadsCanvas.value!.getContext('2d')!;

            for (const position of needsPaintLocations) {
                // Draw the single bead
                this.drawSingleBead(position.x, position.y, appStore.currentCanvas.value, beadsBuffer, backgroundBuffer);

                // Create location variables
                const x = getTileSize(position.x);
                const y = getTileSize(position.y);

                // Draw the buffers
                context.clearRect(x, y, tileSize, tileSize);
                if (this.backgroundImageCanvas.value != null && settingsStore.state.displayBackgroundImage === true) {
                    context.drawImage(this.backgroundImageCanvas.value, x, y, tileSize, tileSize, x, y, tileSize, tileSize);
                }
                context.drawImage(this.backgroundCanvas.value!, x, y, tileSize, tileSize, x, y, tileSize, tileSize);
                context.drawImage(this.beadsCanvas.value!, x, y, tileSize, tileSize, x, y, tileSize, tileSize);
                context.drawImage(this.foregroundCanvas.value!, x, y, tileSize, tileSize, x, y, tileSize, tileSize);
            }
        }
    }

    /** Creates a new HtmlCanvasElement from a HtmlCanvasElement, with the same size as the original. */
    private createCanvasFrom (fromCanvas: HTMLCanvasElement) {
        const buffer = document.createElement('canvas');
        buffer.width = fromCanvas.width;
        buffer.height = fromCanvas.height;
        return buffer;
    }

    private drawSingleBead (x: number, y: number, canvas: Canvas, beadsBuffer: CanvasRenderingContext2D, backgroundBuffer: CanvasRenderingContext2D) {
        const nightMode = settingsStore.state.nightMode;
        // var beadRow = canvas.data[x];
        // var bead: Bead | null = beadRow == null ? null : beadRow[y];
        // let bead = appStore.getCurrentCanvasBead(x, y);
        let bead = this.mapBuffer.get(`${x};${y}`)
        // const beadColor = bead == null ? null : bead.color;
    
        // Draw checkerboard
        const tileSizeX = getTileSize(x)
        const tileSizeY = getTileSize(y)
        const tileSize = getTileSize()
        backgroundBuffer.clearRect(tileSizeX, getTileSize(y), tileSize, tileSize)
        backgroundBuffer!.fillStyle = nightMode ? 'rgba(255, 255, 255, 0.07)' : 'rgba(0, 0, 0, 0.05)'
        backgroundBuffer!.fillRect(tileSizeX, getTileSize(y), tileSize / 2, tileSize / 2)
        backgroundBuffer!.fillRect(tileSizeX + (tileSize / 2),
        tileSizeY + (tileSize / 2),
            tileSize / 2,
            tileSize / 2
        )
    
        // Draw bead color
        if (bead?.color != null) {
            const color = canvas.colors[bead.color];
            // const transparency = color.translucent ? 0.5 : 1;
            const rgbaColor = this.colorToRGB(color, nightMode);
            const xOffset = x * tileSize;
            const yOffset = y * tileSize;
            if (color.translucent) {
                beadsBuffer.clearRect(tileSizeX, tileSizeY, tileSize, tileSize);
            }
            beadsBuffer.fillStyle = rgbaColor;
            beadsBuffer.fillRect(tileSizeX, tileSizeY, tileSize, tileSize);
    
            if (color.shiny) {
                const gradient = beadsBuffer.createLinearGradient(xOffset, yOffset, xOffset + tileSize, yOffset + tileSize);
                gradient.addColorStop(0, 'transparent');
                gradient.addColorStop(0.5, 'rgba(255, 255, 255, 0.15)');
                gradient.addColorStop(1, 'transparent');
                beadsBuffer.fillStyle = gradient;
                beadsBuffer.fillRect(tileSizeX, tileSizeY, tileSize, tileSize);
            }
            } else if (bead?.color == null) {
            // Clear deleted beads
            beadsBuffer.clearRect(x * tileSize, y * tileSize, tileSize, tileSize);
        }
    }

    private colorToRGB (color: Color, nightMode: boolean) {
        let r = parseInt(color.value.slice(1, 3), 16),
            g = parseInt(color.value.slice(3, 5), 16),
            b = parseInt(color.value.slice(5, 7), 16);
        
        const alpha = color.translucent ? 0.5 : 1;
    
        if (nightMode && !color.glowInTheDark) {
            r = r / 5;
            g = g / 5;
            b = b / 5;
        }
    
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    }
}